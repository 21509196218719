<template>
     <v-card
        elevation="10"
        class="mx-auto"
    >
        <v-toolbar
            dark
            cards                
            flat
            outlined
            color="primary"
        >
            <v-card-title class="text-h6 font-weight-regular">
            {{cmpOptions.title}}
            </v-card-title>


            <v-spacer> </v-spacer>
  
            <v-btn 
                @click= "close($event)"
                icon>
                <v-icon>mdi-close-thick</v-icon>
            </v-btn>
 
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
                <v-form
                    ref="form"
                    v-model="form"
                    @submit.prevent="submit"
                    class="pa-4 pt-6"
                >
                    <v-alert type="error" v-if="errMessage != ''">
                    {{errMessage}}
                    </v-alert>
                        <v-text-field
                            v-model="editItem.name"
                            :rules="[rules.required]"
                            label="Name"
                            style="min-height: 96px">
                        </v-text-field>
                        <v-text-field
                            v-model="editItem.street"
                            :rules="[rules.required]"
                            label="Starße"
                            style="min-height: 96px">
                        </v-text-field>
                        <v-text-field
                            v-model="editItem.town"
                            :rules="[rules.required]"
                            label="Stadt"
                            style="min-height: 96px">
                        </v-text-field>

                    <v-divider></v-divider>
                    <v-card-actions>
                    <v-btn
                        
                        outlined
                        @click="$refs.form.reset()"
                    >
                        Reset
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!form"
                        :loading="isLoading"
                        dark
                        color="primary"
                        depressed
                        type="submit"              
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-container>
    </v-card>
</template>

<script>
    export default {
  
 
        props:{
            item: {
                required: false,
                type: Object,
                default: () => undefined
            },
            options:{
                required: false,
                type: Object
            },
            isVisible:{                
                required: true,
                default: false,
                type: Boolean
            }
        },

        data() {
            return {
                defaultOptions:{
                    title: 'Title',
                    isEdit: true
                },
                defaultItem: {
                    name: null,
                    street: null,
                    town: null,
                    enabled: true,
                },
                editItem: {
                    name: null,
                    street: null,
                    town: null,
                    enabled: true,
                },

                rules: {
                    required: v => !!v || 'This field is required'
                }, 
                isLoading: false,
                message: '',
                errMessage: '',
                form: false,
 
            }
        },

        computed:{            
 
            currentUser() {
                return this.$store.state.auth.user;
            },

 
            cmpOptions(){   
                return {...this.defaultOptions,...this.options}
            },

            captionBtnSubmit(){
                return this.cmpOptions.isEdit ? 'Ändern' : 'Speichern';
            },


        },

        methods:{ 


            close(event){
                console.debug('close', event)
                this.$emit('close', event)  
                this.$emit('update:isVisible', false)        
            },

            submit(event){
                console.debug('submit', event)
                

                console.debug('submit', this.editItem)

                const _this = this
                const apicmd = this.cmpOptions.isEdit ? "putApiSetStore" : "postApiSetStore"
                this.$store.dispatch('management/' + apicmd, {path:"api/management/company", param:{ store:"companies", data:this.editItem}})
                .then((response)=>{ 
                    console.log("submit:", response); 
                    this.$emit('close')  

                })
                .catch((error)=>{
                    _this.errMessage = error
                    console.error(error);
                })                

                // if(this.cmpOptions.isEdit){
                    
                //     ManagementService.updateitem(this.editItem).then(
                //         (response) => {
                //             this.$emit('updateitem', response.data.item)  
                //             console.log("submit:", response);
                //         },
                //         (error) => {
                //             console.log("submit:", error.response);
                //             this.errMessage =  ((error.response &&
                //                                         error.response.data &&
                //                                         error.response.data.message) ||
                //                                         error.message ||
                //                                         error.toString())

                //             console.debug('Error',this.errMessage)
                //         }
                        
                //     );          

                // }
                // else{
 
                //     ManagementService.additem(this.editItem).then(
                //         (response) => {
                            
                //             console.log("submit:", response);
                //             this.$emit('additem', response.data.item)  

                //         },
                //         (error) => {
                //             console.log("submit:", error.response);
                //             this.errMessage =  ((error.response &&
                //                                         error.response.data &&
                //                                         error.response.data.message) ||
                //                                         error.message ||
                //                                         error.toString())

                //             console.debug('Error',this.errMessage)
                //         }
                //     );
 
                // }     
       
            },

            // changeRole(item){
            //     console.debug('changeRole')
            //     let obj = this.availableRoles.find(o => o.id === item);
            //     this.selectedRole = obj;
            // }


 
        },
        watch: { 
            isVisible: function(newVal) { // watch it
                this.errMessage  = '';
                console.debug('isVisible');
                if(newVal){
                    this.$refs.form.reset();
                    if(this.cmpOptions.isEdit){
                        console.debug('isVisible', this.item);
                        this.editItem = JSON.parse(JSON.stringify(this.item));
                    }
                    else{
                        
                        this.editItem = JSON.parse(JSON.stringify(this.defaultItem));
                    }
                }
                else{
                    this.editItem = JSON.parse(JSON.stringify(this.defaultItem));
                    this.selectedRoleId = undefined;
                }

                console.log('Prop changed: isVisible', newVal, ' | user: ', this.editItem)
            }
        },
        mounted() {
            this.errMessage  = '';
            console.debug('mounted');
            if(this.cmpOptions.isEdit){
                console.debug('isVisible', this.item);
                this.editItem = JSON.parse(JSON.stringify(this.item));
            }
            else{
                
                this.editItem = JSON.parse(JSON.stringify(this.defaultItem));
            }
        }

    }

</script>